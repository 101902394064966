
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































































































































































































































































































































































/* stylelint-disable declaration-no-important */
.contact-popup,
[class*='contact-popup--'] {
  @extend %box-shadow;

  overflow: hidden;
  width: 90vw;
  max-width: 54rem;
  // height: 95vh; // See desktop !
  height: auto;
  padding: $spacing * 2 $spacing 0;
  background-color: $white;
  border-radius: $border-radius;

  @include mq(m) {
    padding: $spacing * 2 $spacing * 1.5 0;
  }
}

.contact-popup.is-form {
  @include mq(xs) {
    height: 60rem;
  }

  @include mq(m) {
    height: 62rem;
  }

  @include mq(l) {
    height: inherit;
  }
}

.contact-popup__back {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  padding: 1rem;
  background: transparent;
  border: 0;

  svg {
    width: 2rem;
    height: 2rem;
    transform: rotate(180deg);
  }
}

.contact-popup-inner {
  position: relative;

  > * {
    padding: 0 $spacing / 2;
  }
}

.contact-popup__logo {
  width: 100%;
  max-width: 9rem;
  margin: 0 0 $spacing * 2;

  @include mq(m) {
    margin: 0 0 $spacing * 2;
  }
}

.contact-popup__row {
  margin: 0 -4rem 0 -7rem;
  padding: $spacing * 2 7rem;

  &:nth-child(odd) {
    background-color: $c-gray-lightest;
  }
}

.contact-popup__text {
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.2;
}

.contact-popup__content {
  transition: transform 0.2s ease-out;

  .is-form & {
    transform: translateX(calc(-100% - 6rem));
  }
}

.contact-popup__form {
  @include get-all-space;

  transition: transform 0.2s ease-out;
  transform: translateX(calc(100% + 6rem));

  .is-form & {
    transform: translateX(0);
  }

  .contact-popup__inputs__input {
    &:not(:last-child) {
      margin-bottom: $spacing / 2;
    }
  }
}

.contact-popup__form__title {
  margin: $spacing 0;
}

.contact-popup__form__subtitle {
  margin-bottom: $spacing;
}

.contact-popup__form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing;

  .grecaptcha-badge {
    position: relative !important;
    right: initial !important;
    bottom: initial !important;
    width: 7rem !important;
    box-shadow: none !important;
  }
}

.contact-popup__call-us {
  // border: 1px solid rgba($c-pink-medium, 0.3);
  padding: $spacing * 0.5 0;
  border-radius: 8px;

  .call-us {
    margin: 0;
  }

  .call-us__headline {
    margin-bottom: $spacing * 0.25;
    font-size: 1.1rem;
  }

  .call-us__hotline {
    margin-bottom: $spacing * 0.75;
  }

  .call-us__hotline__icon {
    width: 3.4rem;
    height: 3.4rem;

    .icon {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .call-us__hotline__number {
    font-size: 3.4rem;
  }

  .call-us__hotline__text {
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  @include mq(m) {
    padding: $spacing;
  }

  @include mq(l) {
    padding: 0;
    padding-top: $spacing * 1.5;
    padding-bottom: $spacing * 1.5;
  }
}

.contact-popup__separator {
  @extend %fw-bold;
  @extend %text-center;

  position: relative;
  margin-top: $spacing;
  margin-bottom: $spacing;

  &::after {
    @include center-xy;

    content: '';
    display: block;
    width: 100%;
    height: 0.1rem;
    background-color: $gray-lightest;
  }
}

.contact-popup__separator__label {
  position: relative;
  z-index: 1;
  padding: 0 $spacing;
  color: $gray-lighter;
  background-color: $white;
}

// FORM
.contact-popup__form__errors,
.contact-popup__form__error {
  display: none;

  &.is-visible {
    display: block;
  }
}

.contact-popup__form__validation {
  ::v-deep {
    font-size: 1.6rem;
    line-height: 1.4;

    strong {
      @extend %fw-bold;

      margin-bottom: $spacing * 0.5;
      display: inline-block;
      color: $c-green-light;
      font-size: 1.8rem;
    }
  }
}

.contact-popup__form__error,
.contact-popup__form__error__captcha {
  @extend %fw-bold;

  color: $c-error;
}
